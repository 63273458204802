<!--
 * @Description: 房间显示
 * @Date: 2022-03-16 17:40:28
 * @LastEditTime: 2022-03-29 16:13:06
-->
<template>
  <div class="rtc-container">
    <!-- 进房操作区域 -->
    <div class="Call" v-show="roomId" style="background-color: #fff;margin-top: 5px;">
      <div class="remote-container" id="localStream" style="height:600px">
        <div id="local" class="remote-stream-container" ref="logContainer"> </div>
        <div v-if="remoteUsersViews.length == 0" class="local-stream-content"
          style="height: 650px;background-color: black;">
        </div>
        <div v-else v-for="(item) in remoteUsersViews" :key="item" :id="item" class="local-stream-content"
          style="height: 650px">
        </div>
        <div style="display:flex;justify-content: center;margin-top: 5px;">
          <el-button type="primary" icon="el-icon-turn-off-microphone"
            style="background-color: #F62509;border-color: #F62509;width:180px;height: 40px;" @click="gd">挂断</el-button>
        </div>
      </div>
    </div>
    <div v-show="!roomId" style="background-color: #fff;margin-top: 5px;">
      <div style="height:600px">
        <img src="../assets/image/wjt.png" alt="" style="width: 80%;margin-left: 8%;margin-top: 30px;">
      </div>
      <div style="display:flex;justify-content: center;margin-top: 40px;"> <el-button type="primary"
          icon="el-icon-phone" @click="hj"
          style="background-color: #01B252;border-color: #01B252;margin-right: 10px;width:180px;height: 40px;">呼叫</el-button>
        <el-button type="primary" icon="el-icon-turn-off-microphone"
          style="background-color: #F62509;border-color: #F62509;width:180px;height: 40px;" @click="gd">挂断</el-button>
      </div>
    </div>
    <div style="width:400px;padding-top: 15px; background-color: #ffff;margin-top: 5px;padding-right: 20px;">
      <div style="display:flex">
        <div class="titleicon"></div>
        <h3 class="h2">问诊信息</h3>
      </div>
      <div style="display:flex;margin-top:10px;font-size: 20px;">
        <div><span style="font-size: 22px;font-weight: 600;">{{ patientInfo.residentName }}</span></div>
        <div style="margin-left:9px;font-size: 17px;padding-top: 6px;">{{ patientInfo.age }}岁</div>
        <div style="margin-left:9px;margin-right:9px;font-size: 16px;padding-top: 6px;">|</div>
        <div style="margin-left:6px">
          <div style="font-size: 17px;padding-top:6px;" v-if="patientInfo.gender === '2'">女</div>
          <div style="font-size: 17px;padding-top: 6px;" v-if="patientInfo.gender === '1'">男</div>
        </div>
      </div>
      <div style="margin-top: 10px;font-size: 20px;">联系电话：<span style="margin-left:10px;color: #979797;">{{
        patientInfo.phone }}</span></div>
      <div style="margin-top: 10px;font-size: 20px;">身份证号：<span style="margin-left:10px;color: #979797;">{{
        patientInfo.identity }}</span></div>
      <div style="margin-top: 10px;font-size: 20px;">家庭住址：<span style="margin-left:10px;color: #979797;">{{
        patientInfo.address }}</span></div>
      <div style="width:400px;padding-top: 20px;">
        <div style="display:flex">
          <div class="titleicon"></div>
          <h3 class="h2"> 问题描述:</h3>
        </div>
        <el-input style="margin-top:20px;padding-right: 20px;" type="textarea" v-model="description"></el-input>
      </div>
      <div style="width:400px;padding-top: 20px;margin-right:20px">
        <div style="display:flex">
          <div class="titleicon"></div>
          <h3 class="h2"> 指导建议:</h3>
        </div>
        <el-input style="margin-top:20px;padding-right: 20px;height: 200px;" type="textarea"
          v-model="guidance"></el-input>
      </div>
      <div style="width:400px;padding-top: 20px;text-align: right;padding-right: 20px;">
        <el-button style="background:#08A4BB;color:#fff" @click="submit">保存</el-button>
      </div>
    </div>
    <div class='basicInfo' style="padding-top: 15px;margin-left:30px;margin-top: 5px;height: 900px;">
      <div class="BasicInfo">
        <div style="display:flex">
          <div class="titleicon"></div>
          <h3 class="h2"> 风险评估结果:</h3>
        </div>
        <div class="history">
          <el-button v-if="Number(healthIndex) >= 850" style="background:#F6FBFC;color:#67C78B;border-color:#01B252">{{
            healthIndex }}</el-button>
          <el-button v-else-if="Number(healthIndex) >= 650"
            style="background:#F6FBFC;color:#ff9900;border-color:#ffcc99">{{ healthIndex }}</el-button>
          <el-button v-else style="background:#F6FBFC;color:#ff0000;border-color:#ff0033">{{ healthIndex }}</el-button>
        </div>
        <div style="display:flex">
          <div class="titleicon"></div>
          <h3 class="h2">近期就诊信息</h3>
        </div>
        <div class="history">
          <div class="userbasic">
            <div>
              <span class="title" style="font-weight: 600;">就诊单位:</span><span class="value" style="margin-left:20px"> {{
                visitRecord.clinic }}</span>
            </div>
            <div>
              <span class="title" style="font-weight: 600;">就诊时间:</span><span class="value" style="margin-left:20px">{{
                visitRecord.therapyDate }}</span>
            </div>
            <div>
              <span class="title" style="font-weight: 600;">诊断结果： </span><span class="value" style="margin-left:20px">{{
                visitRecord.diagnosis }}</span>
            </div>
            <div>
              <span class="title" style="font-weight: 600;">医嘱：</span><span class="value" style="margin-left:20px">{{
                visitRecord.medicalAdvice }}</span>
            </div>
          </div>
        </div>
        <div>
          <div style="display: flex;">
            <div class="titleicon"></div>
            <h3 class="h2">年度管理达标情况</h3>
          </div>
          <div style="display: flex;width: 100%;">
            <div style="width: 40%;">
              <div id="echartsbp" style="height:200px;width: 100%;"></div>
              <div style="margin-top:20px;font-weight:600;margin-left:40px">达标次数: <span>{{ bpCtrlRate.satNum }}</span>
              </div>
              <div style="margin-top:20px;font-weight:600;margin-left:40px">未达标次数: <span>{{ bpCtrlRate.unSatNum
                  }}</span></div>
              <div style="margin-top:20px;font-weight:600;margin-left:40px">达标率: <span>{{ bpCtrlRate.ctrlRate }}%</span>
              </div>
            </div>
            <div style="width: 40%;">
              <div id="echartsbg" style="height:200px;width: 100%;"></div>
              <div style="margin-top:20px;font-weight:600;margin-left:40px">达标次数: <span>{{ bgCtrlRate.satNum }}</span>
              </div>
              <div style="margin-top:20px;font-weight:600;margin-left:40px">未达标次数: <span>{{ bgCtrlRate.unSatNum
                  }}</span></div>
              <div style="margin-top:20px;font-weight:600;margin-left:40px">达标率: <span>{{ bgCtrlRate.ctrlRate }}%</span>
              </div>
            </div>
          </div>
        </div>
        <div style="display:flex;margin-bottom: 10px;margin-top: 40px;">
          <div class="titleicon"></div>
          <h3 class="h2">目前用药情况</h3>
        </div>
        <el-table :data="drugData" stripe style="width: 100%;overflow-y: auto">
          <el-table-column prop="type" label="用药类型" align-items="center">
            <template slot-scope="scope">
              <div v-if="scope.row.type === '1'">
                高血压用药
              </div>
              <div v-else>
                糖尿病用药
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="药物名称" align-items="center">
          </el-table-column>
          <el-table-column prop="frequency" align-items="center" label="单位">
          </el-table-column>
          <el-table-column prop="dose" align-items="center" label="剂量">
          </el-table-column>
          <el-table-column prop="frequency" align-items="center" label="频率次级量">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import rtc from '../components/mixins/rtc.js';
import TRTC from 'trtc-sdk-v5';
import LibGenerateTestUserSig from '@/utils/lib-generate-test-usersig.min.js';
import axios from "axios";

export default {
  name: 'App',
  mixins: [rtc],
  data() {
    return {
      url: 'https://hekou-nurse-api.xinyilu.cn/fado/dy',
      // url: 'http://8.131.93.145:54008/fd',
      identity: '',
      patientInfo: {},
      healthIndex: '1000',
      visitRecord: {},
      bpCtrlRate: {},
      bgCtrlRate: {},
      drugData: [],
      userId: '',
      roomId: '',
      recordId: '',
      sdkAppId: 1400236771,
      secretKey: "83ab78d1a8513af6626d58cc2bacd7b28bfb2af06515fa02b0204129ebb53422",
      userSig: '',
      description: '',
      guidance: '',
      type: '', // 进入模式 invite: 被邀请
      cameraId: '',
      microphoneId: ''
    };
  },
  mounted() {
    this.getDevice()
    this.identity = this.$route.query.identity;
    this.userId = this.$route.query.userId;
    this.type = this.$route.query.type;
    if (this.type && this.type === 'invite') {
      this.roomId = Number(this.$route.query.roomId);
      this.recordId = this.$route.query.recordId;
      this.handleJoinRoom()
    }
    axios.get(this.url + '/pc/video/patientInfo/' + this.identity).then(res => {
      this.patientInfo = res.data.data
    })
    axios.get(this.url + '/pc/video/healthIndex/' + this.identity).then(res => {
      this.healthIndex = res.data.data
    })
    axios.get(this.url + '/pc/video/lastVisitInfo/' + this.identity).then(res => {
      this.visitRecord = res.data.data
    })
    axios.get(this.url + '/pc/video/bpCtrlRate/' + this.identity).then(res => {
      this.bpCtrlRate = res.data.data
      this.echartsbp()
    })
    axios.get(this.url + '/pc/video/bgCtrlRate/' + this.identity).then(res => {
      this.bgCtrlRate = res.data.data
      this.echartsbg()
    })
    axios.get(this.url + '/pc/video/drugRecord/' + this.identity).then(res => {
      this.drugData = res.data.data
    })
  },
  beforeDestroy() {
    this.gd();
  },
  methods: {
    async getDevice() {
      let cameraList = await TRTC.getCameraList();
      let microphoneList = await TRTC.getMicrophoneList();
      this.cameraId = cameraList[0].deviceId
      this.microphoneId = microphoneList[0].deviceId
      console.log(cameraList)
      console.log(microphoneList)
    },
    gd() {
      this.handleLeave()
      this.clearRoomId()
      axios.get(this.url + '/pc/video/doctor/exitRoom/' + this.recordId + '/' + this.userId).then(res => {
        console.log(res.data)
      })
    },
    hj() {
      let param = {
        sourceName: this.patientInfo.doctorName,
        sourceKey: this.patientInfo.doctorId,
        targetKey: this.patientInfo.identity,
        targetName: this.patientInfo.residentName,
        identity: this.patientInfo.identity
      }
      let that = this
      axios.post(this.url + '/pc/video/getRoomId', param).then(res => {
        that.roomId = Number(res.data.data.roomId)
        that.recordId = res.data.data.recordId
        setTimeout(function () {
          that.handleJoinRoom()
        }, 200)
      })
    },
    echartsbp() {

      var echarts = require("echarts");
      this.option = echarts.init(document.getElementById("echartsbp"));
      let option = {
        title: {
          zlevel: 0,
          text: '血压控制率',
          top: 'center',
          left: '46%',
          textAlign: 'center',
        },
        color: ['#01B252', '#FF7723'],
        tooltip: {
          trigger: 'item'
        },

        legend: {
          show: false,
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['60%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [{
              name: '达标次数',
              value: this.bpCtrlRate.satNum
            }, {
              name: '未达标次数',
              value: this.bpCtrlRate.unSatNum
            }]
          }
        ]
      };
      this.option.setOption(option);
    },
    echartsbg() {

      var echarts = require("echarts");
      this.option = echarts.init(document.getElementById("echartsbg"));
      let option = {
        title: {
          zlevel: 0,
          text: '血糖控制率',
          top: 'center',
          left: '46%',
          textAlign: 'center',
        },
        tooltip: {
          trigger: 'item'
        },
        color: ['#01B252', '#FF7723'],
        legend: {
          show: false,
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['60%', '90%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [{
              name: '达标次数',
              value: this.bgCtrlRate.satNum
            }, {
              name: '未达标次数',
              value: this.bgCtrlRate.unSatNum
            }]
          }
        ]
      };
      this.option.setOption(option);
    },
    submit() {
      let param = {
        identity: this.identity,
        description: this.description,
        guidance: this.guidance,
        recordId: this.recordId,
      }

      axios.post(this.url + '/pc/video/save', param).then(res => {
        console.log(res.data)
        this.$message({
          message: "保存成功",
          type: "success",
        });
      })
    },
    // 点击【Join Room】按钮
    async handleJoinRoom() {
      if (!this.sdkAppId || !this.secretKey) {
        alert('Please enter sdkAppId and secretKey');
        return;
      }
      if (!this.userId || !this.roomId) {
        alert('Please enter userId and roomId');
        return;
      }
      const userSigGenerator = new LibGenerateTestUserSig(this.sdkAppId, this.secretKey, 604800);
      this.userSig = userSigGenerator.genTestUserSig(this.userId);
      await this.enterRoom();
      await this.handleStartLocalAudio();
      await this.handleStartLocalVideo();
      axios.get(this.url + '/pc/video/doctor/addRoom/' + this.recordId + '/' + this.userId).then(res => {
        console.log(res.data)
      })
    },

    // 点击【Leave Room】按钮
    async handleLeave() {
      await this.exitRoom();
    },
    clearRoomId() {
      setTimeout(() => {
        this.roomId = ''
      }, 200)
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table .el-table__header-wrapper th,
.el-table .el-table__fixed-header-wrapper th {
  background: #08a4bb !important;
  color: #fff !important;
  height: 60px !important;
  font-size: 16px;
}

.report {
  padding: 50px 80px;
  width: 30%;
  height: 700px;

  .border {
    width: 100%;
    border: 2px dashed #ccc;
  }

  .title {
    position: relative;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;

    :nth-child(2) {
      position: absolute;
      right: 30px;
    }
  }

  .form-head {
    border-top: 1px dashed #ccc;
    display: flex;

    .el-form-item__label {
      color: #333;
      font-size: 16px;
      height: 50px;
    }

    .el-form-item {
      height: 100%;
    }
  }

  .form-bottom {
    display: flex;
    justify-content: space-between;

    .el-form-item__label {
      color: #333;
      font-size: 16px;
      height: 50px;
    }

    .el-form-item {
      height: 100%;
    }
  }

  .el-form-item {
    border-bottom: 1px solid #cecece;
    margin-bottom: 0;
    height: 22vh;
    display: flex;
    align-items: center;
  }

  .el-form-item__label {
    width: 150px;
    font-size: 18px;
    color: #007eff;
    display: flex;
    align-items: center;
    background: #f5f7f8;
    height: 100%;
    margin-right: 10px;
  }
}

.basicInfo {
  width: 40%;
  border-radius: 10px;
  background: #ffff;
  padding: 25px;
}

.BasicInfo {
  background: white;
  padding: 20px;

  width: 100%;
  min-height: 92vh;

  .username {
    display: flex;
    align-items: center;

    :nth-child(1) {
      font-size: 26px;
      color: #007eff;
    }

    :nth-child(2) {
      font-size: 14px;
      color: #333;
      margin-left: 20px;
    }
  }

  .userbasic {

    margin-top: 15px;
    line-height: 35px;
    width: 350px;

    .title {

      font-size: 16px;
    }

    .value {
      margin-right: 30px;
    }
  }

  .history {
    margin: 15px 0;
  }

  .h2 {

    font-size: 18px;
  }

  .file {
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;

    .el-image {
      margin-right: 15px;
    }
  }
}

.Call {
  width: 40%;
  padding: 50px 80px;
  position: relative;

  .video-box {
    width: 100%;
  }

  .el-button--danger {
    position: absolute;
    top: 50px;
    right: 80px;
  }
}

.rtc-container {
  background: url(../assets/image/bg.png) 100% 100%;
  display: flex;
  width: 100%;
  padding: 10px;

  .label {
    margin: 14px 0 6px;
    text-align: left;
    font-weight: bold;
  }

  .control-container {
    text-align: left;
    margin-bottom: 10px;

    div:not(:nth-last-child(1)) {
      margin-bottom: 10px;
    }

    .button:not(:first-child) {
      margin-left: 2px;
    }
  }

  .invite-link-container {
    width: 100%;
    color: #084298;
    background-color: #cfe2ff;
    position: relative;
    padding: 10px 16px;
    margin-bottom: 16px;
    border: 1px solid #b6d4fe;
    border-radius: 0.25rem;

    .invite-input {
      margin-top: 10px;
    }

    .invite-btn {
      display: flex;
      cursor: pointer;
    }
  }

  .info-container {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .log-container {
      flex-grow: 1;
      border: 1px solid #dddddd;
      height: 360px;
      padding: 10px;
      margin-right: 16px;
      overflow-y: scroll;

      .log-label {
        margin: 0 0 6px;
        font-weight: bold;
      }

      .log-state {
        display: inline-block;
        margin-right: 6px;
      }

      >div {
        font-size: 12px;
      }
    }

    .local-stream-container {
      width: 100%;
      height: 600px;
      position: relative;
      flex-shrink: 0;

      .local-stream-content {
        position: sticky;
        bottom: 10px;
        width: 200px;
        height: 600px;

      }

      .local-stream-control {
        width: 90%;
        height: 30px;
        position: absolute;
        bottom: 1px;

        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 0 10px;

        .control {
          margin-left: 10px;
        }

        .icon-class {
          color: #fff;
          cursor: pointer;
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  .info-container-mobile {
    display: block;

    .log-container {
      margin-right: 0;
    }

    .local-stream-container {
      width: 320px;
      height: 240px;
      margin-top: 10px;
    }
  }

  ::v-deep .el-textarea__inner {
    height: 200px;
    border-color: #35B4C6;
  }

  .remote-container {
    width: 100%;
    flex-wrap: wrap;

    .remote-stream-container {
      width: 10%;
      position: fixed;
      top: 30px;
      left: 20px;
      z-index: 2;
      left: 23%;
      height: 240px;
      margin: 0 10px 10px 0;
    }
  }
}
</style>
<style>
.titleicon1 {
  width: 16px;
  height: 16px;
  background: #868686;
  margin-top: 9px;
  margin-right: 5px;
  border-radius: 8px;
}

.titleicon {
  width: 4px;
  height: 22px;
  background: #10c5da;
  margin-right: 5px;
  border-radius: 15px;
}

.el-collapse-item__header {
  font-size: 20px !important;
  font-weight: 700;
  color: #00c4c2;
}
</style>
