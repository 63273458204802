import TRTC from 'trtc-sdk-v5';

export default {
  data() {
    return {
      trtc: null,
      remoteUsersViews: [],
      isMutedVideo: false,
      isMutedAudio: false,
      camStatus: 'stopped', // stopped, starting, started, stopping
      micStatus: 'stopped',
      roomStatus: 'exited', // exited, exiting, entering, entered
      shareStatus: 'stopped', // stopping, stopped, sharing, shared
    };
  },

  methods: {
    initTRTC() {
      if (this.trtc) return;
      this.trtc = TRTC.create();
    },

    async enterRoom() {
      this.roomStatus = 'entering';
      this.initTRTC();
      try {
        await this.trtc.enterRoom({
          roomId: this.roomId,
          sdkAppId: parseInt(this.sdkAppId, 10),
          userId: this.userId,
          userSig: this.userSig,
        });
        this.roomStatus = 'entered';

        await this.installEventHandlers();
        await this.startGetAudioLevel();
      } catch (error) {
        this.roomStatus = 'exited';
        console.error('enterRoom room failed', error);
        throw error;
      }
    },

    async handleStartLocalAudio() {
      this.micStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalAudio({
          option: {
            microphoneId: this.microphoneId,
          },
        });
        this.isMutedAudio = false;
        this.micStatus = 'started';
      } catch (error) {
        this.micStatus = 'stopped';
        throw error;
      }
    },

    async handleStopLocalAudio() {
      if (this.micStatus !== 'started') {
        return;
      }
      this.micStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalAudio();
        this.micStatus = 'stopped';
      } catch (error) {
        this.micStatus = 'started';
        throw error;
      }
    },

    async handleStartLocalVideo() {
      this.camStatus = 'starting';
      this.initTRTC();
      try {
        await this.trtc.startLocalVideo({
          view: 'local',
          option: {
            cameraId: this.cameraId,
            profile: '1080p',
          },
        });
        this.camStatus = 'started';
        this.isMutedVideo = false;
      } catch (error) {
        this.camStatus = 'stopped';
        throw error;
      }
    },

    async handleStopLocalVideo() {
      if (this.camStatus !== 'started') {
        return;
      }
      this.camStatus = 'stopping';
      this.initTRTC();
      try {
        await this.trtc.stopLocalVideo();
        this.camStatus = 'stopped';
      } catch (error) {
        this.camStatus = 'started';
        throw error;
      }
    },

    async exitRoom() {
      if (this.roomStatus !== 'entered') {
        return;
      }
      this.roomStatus = 'exiting';
      this.stopGetAudioLevel();

      try {
        await this.trtc.exitRoom();
        this.roomStatus = 'exited';
        this.remoteUsersViews = [];
        await this.uninstallEventHandlers();

      } catch (error) {
        this.roomStatus = 'entered';
        throw error;
      }

      if (this.micStatus === 'started') this.handleStopLocalAudio();
      if (this.camStatus === 'started') this.handleStopLocalVideo();
    },

    async muteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: true });
        this.isMutedVideo = true;
      } catch (error) {
        console.log(error)
      }
    },

    async muteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: true });
        this.isMutedAudio = true;
      } catch (error) {
        console.log(error)
      }
    },

    async unmuteVideo() {
      try {
        await this.trtc.updateLocalVideo({ mute: false });
        this.isMutedVideo = false;
      } catch (error) {
        console.log(error)
      }
    },

    async unmuteAudio() {
      try {
        await this.trtc.updateLocalAudio({ mute: false });
        this.isMutedAudio = false;
      } catch (error) {
        console.log(error)
      }
    },

    async switchDevice(type, deviceId) {
      try {
        if (type === 'video' && this.camStatus === 'started') {
          await this.trtc.updateLocalVideo({
            option: { cameraId: deviceId },
          });
        }
        if (type === 'audio' && this.micStatus === 'started') {
          await this.trtc.updateLocalAudio({
            option: { microphoneId: deviceId },
          });
        }
      } catch (error) {
        console.error('switchDevice failed', error);
      }
    },

    startGetAudioLevel() {
      this.trtc.on(TRTC.EVENT.AUDIO_VOLUME, (event) => {
        event.result.forEach(({ userId, volume }) => {
          const isMe = userId === '';
          if (isMe) {
            console.log(`my volume: ${volume}`);
          } else {
            console.log(`user: ${userId} volume: ${volume}`);
          }
        });
      });
      this.trtc.enableAudioVolumeEvaluation(2000);
    },

    stopGetAudioLevel() {
      this.trtc && this.trtc.enableAudioVolumeEvaluation(-1);
    },

    installEventHandlers() {
      this.trtc.on(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.on(TRTC.EVENT.KICKED_OUT, this.handleKickedOut);
      this.trtc.on(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.on(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable);
      this.trtc.on(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable);
      this.trtc.on(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.on(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
    },

    uninstallEventHandlers() {
      this.trtc.off(TRTC.EVENT.ERROR, this.handleError);
      this.trtc.off(TRTC.EVENT.KICKED_OUT, this.handleKickedOut);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_ENTER, this.handleRemoteUserEnter);
      this.trtc.off(TRTC.EVENT.REMOTE_USER_EXIT, this.handleRemoteUserExit);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_AVAILABLE, this.handleRemoteVideoAvailable);
      this.trtc.off(TRTC.EVENT.REMOTE_VIDEO_UNAVAILABLE, this.handleRemoteVideoUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_UNAVAILABLE, this.handleRemoteAudioUnavailable);
      this.trtc.off(TRTC.EVENT.REMOTE_AUDIO_AVAILABLE, this.handleRemoteAudioAvailable);
      this.trtc.off(TRTC.EVENT.SCREEN_SHARE_STOPPED, this.handleScreenShareStopped);
    },

    handleError(error) {
      alert(error);
    },

    async handleKickedOut(event) {
      console.log(event)
      this.trtc = null;
      await this.exitRoom();
    },

    handleRemoteUserEnter(event) {
      const { userId } = event;
      console.log(userId)
    },

    handleRemoteUserExit(event) {
      console.log(event)
      this.$message({
        message: "对方已离开房间",
        type: "success",
      });
      this.gd()
    },

    handleRemoteVideoAvailable(event) {
      const { userId, streamType } = event;
      try {
        if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
          this.remoteUsersViews.push(`${userId}_main`);
          this.$nextTick(async () => {
            await this.trtc.startRemoteVideo({ userId, streamType, view: `${userId}_main` });
          });
        } else {
          this.remoteUsersViews.push(`${userId}_screen`);
          this.$nextTick(async () => {
            await this.trtc.startRemoteVideo({ userId, streamType, view: `${userId}_screen` });
          });
        }
      } catch (error) {
        console.log(error)
      }
    },

    handleRemoteVideoUnavailable(event) {
      const { streamType } = event;
      this.trtc.stopRemoteVideo({ userId: event.userId, streamType });
      if (streamType === TRTC.TYPE.STREAM_TYPE_MAIN) {
        this.remoteUsersViews = this.remoteUsersViews.filter(userId => userId !== `${event.userId}_main`);
      } else {
        this.remoteUsersViews = this.remoteUsersViews.filter(userId => userId !== `${event.userId}_screen`);
      }
    },

    handleRemoteAudioUnavailable(event) {
      console.log(event)
    },

    handleRemoteAudioAvailable(event) {
      console.log(event)
    },

    handleScreenShareStopped() {
      this.shareStatus = 'stopped';
    }
  },
};
