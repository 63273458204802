/*
 * @Description: 全局样式
 * @Date: 2022-03-09 16:42:16
 * @LastEditTime: 2022-03-29 16:36:04
 */
import Vue from 'vue';
import App from './App.vue';
import '@/utils/aegis.js';
import * as echarts from 'echarts';
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import router from './router';
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts;
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
